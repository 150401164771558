import { render, staticRenderFns } from "./testPage_detail.vue?vue&type=template&id=6aa6571e&scoped=true&"
import script from "./testPage_detail.vue?vue&type=script&lang=js&"
export * from "./testPage_detail.vue?vue&type=script&lang=js&"
import style0 from "./testPage_detail.vue?vue&type=style&index=0&id=6aa6571e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aa6571e",
  null
  
)

export default component.exports